import { UserProvider } from '../context/user/UserProvider'
import { SiteProvider } from '../context/site/SiteProvider'
import { ProductProvider } from '../context/products/ProductProvider'
import { PricingProvider } from '../context/pricing/PricingProvider'
import { ShowHideProvider } from '../context/showhide/ShowHideProvider'
import { WarehouseProvider } from '../context/warehouse/WarehouseProvider'
import { StaffProvider } from '../context/staff/StaffProvider'
import { DeliveryProvider } from '../context/delivery/DeliveryProvider'
import { SettingsProvider } from '../context/settings/SettingsProvider'
import { CustomerProvider } from '../context/customers/CustomerProvider'
import { AccountingProvider } from '../context/accounting/AccountingProvider'
import { InventoryProvider } from '../context/inventory/InventoryProvider'
import { IamProvider } from '../context/iam/IamProvider'
import { ShippingProvider } from '../context/shipping/ShippingProvider'
import Compose from '../helpers/Compose'
import { FilterProvider } from './filters/FilterProvider'
import { OrderProvider } from './orders/OrderProvider'

export interface IContextProviders {
  children?: React.ReactNode
}

const ContextProviders: React.FC<IContextProviders> = ({ children }) => {
  return (
    <Compose
      providers={[
        ShowHideProvider,
        UserProvider,
        SiteProvider,
        SettingsProvider,
        IamProvider,
        StaffProvider,
        CustomerProvider,
        AccountingProvider,
        InventoryProvider,
        DeliveryProvider,
        ProductProvider,
        PricingProvider,
        WarehouseProvider,
        ShippingProvider,
        FilterProvider,
        OrderProvider,
      ]}
    >
      {children}
    </Compose>
  )
}

export default ContextProviders
